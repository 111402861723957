import React from 'react';
import * as styles from './nationTemplate.module.scss';
import classNames from 'classnames/bind';
import { graphql } from 'gatsby';
import CategoryTemplate from './categoryTemplate';
import { Logo } from '../components/Logo';
import moment from 'moment';

const cx = classNames.bind(styles);

const Flag = ({ className, nation }) => {
  const colors = nation.colors?.split(/\s*:\s*/g).map(x => x.trim());

  return (
    <div className={cx('flagContainer', className)}>
      <div className={cx('flag')}>
        {colors && (
          <div className={cx('colors')}>
            {colors.map((color, i) => (
              <span style={{ backgroundColor: color }} key={i} />
            ))}
          </div>
        )}
        <Logo />
      </div>
    </div>
  );
};

export default function Nation({
  data: { nation, nations, ...data },
  pageContext,
}) {
  const renderBeforeContent = () => {
    return (
      <>
        <Flag nation={nation} className={cx('nationFlag')} />
        <table className={cx('nationStats')}>
          <tbody>
            <tr>
              <th>Population</th>
              <td>{nation.population}</td>
            </tr>
            <tr>
              <th>GDP per capita</th>
              <td>{nation.gdpPerCapita}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <CategoryTemplate
        data={data}
        renderBeforeContent={renderBeforeContent}
        pageContext={pageContext}
      />
    </>
  );
}

export const pageQuery = graphql`
  query CategoryQuery(
    $ids: [String]!
    $sort: SortOrderEnum
    $categorySlug: String
    $categoryPageId: String
    $showPosts: Boolean!
    $isoCode: String!
  ) {
    categoryPage: markdownRemark(id: { eq: $categoryPageId }) {
      html
      fields {
        encrypted
      }
      frontmatter {
        url
        title
        author
        location
        showTitle
        twitter
        facebook
        albums
        showMeta
        showLink
        dateString: date
        date(formatString: "ddd Do MMMM YYYY H:mm:ss a z")
        className
      }
    }
    allMarkdownRemark(
      sort: { order: [$sort], fields: [frontmatter___date] }
      filter: {
        id: { in: $ids }
        frontmatter: {
          pin: { ne: true }
          categoryPins: { nin: [$categorySlug] }
          show: { ne: $showPosts }
        }
      }
      limit: 4000
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          fields {
            slug
            encrypted
          }
          frontmatter {
            author
            location
            title
            showRead
            showMeta
            dateString: date
            date(formatString: "ddd Do MMMM YYYY H:mm:ss a z")
            size
          }
          hero {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 1.77
                transformOptions: { fit: COVER, cropFocus: ATTENTION }
              )
            }
          }
        }
      }
    }
    pinned: allMarkdownRemark(
      sort: { order: [ASC], fields: [frontmatter___date] }
      filter: {
        id: { in: $ids }
        frontmatter: {
          pin: { eq: true }
          categoryPins: { nin: [$categorySlug] }
          show: { ne: $showPosts }
        }
      }
      limit: 4000
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          fields {
            slug
            encrypted
          }
          frontmatter {
            author
            location
            title
            showRead
            showMeta
            pinOrder
            dateString: date
            date(formatString: "ddd Do MMMM YYYY H:mm:ss a z")
            size
          }
          hero {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 1.77
                transformOptions: { fit: COVER, cropFocus: ATTENTION }
              )
            }
          }
        }
      }
    }
    categoryPinned: allMarkdownRemark(
      sort: { order: [ASC], fields: [frontmatter___date] }
      filter: {
        id: { in: $ids }
        frontmatter: {
          categoryPins: { in: [$categorySlug] }
          show: { ne: $showPosts }
        }
      }
      limit: 4000
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          fields {
            slug
            encrypted
          }
          frontmatter {
            author
            location
            title
            showRead
            showMeta
            pinOrder
            dateString: date
            date(formatString: "ddd Do MMMM YYYY H:mm:ss a z")
            size
          }
          hero {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 1.77
                transformOptions: { fit: COVER, cropFocus: ATTENTION }
              )
            }
          }
        }
      }
    }
    nation: googleSpreadsheetNations(isoCode: { eq: $isoCode }) {
      gdp
      gdpPerCapita
      isoCode
      isoA3Code

      name
      population
      colors
      invitedDate
      referendumDate
      accessionDate
    }
    nations: allGoogleSpreadsheetNations {
      nodes {
        gdp
        gdpPerCapita
        isoCode
        isoA3Code

        name
        population
        colors
        invitedDate
        referendumDate
        accessionDate
      }
    }
  }
`;
