// extracted by mini-css-extract-plugin
export var colors = "nationTemplate-module--colors--30540";
export var consideration = "nationTemplate-module--consideration--59fb6";
export var flag = "nationTemplate-module--flag--e5d45";
export var flagContainer = "nationTemplate-module--flag-container--84792";
export var map = "nationTemplate-module--map--2101f";
export var nation = "nationTemplate-module--nation--0c30a";
export var nationFlag = "nationTemplate-module--nation-flag--ad773";
export var nationMeta = "nationTemplate-module--nation-meta--c332b";
export var nationName = "nationTemplate-module--nation-name--795ff";
export var nationStats = "nationTemplate-module--nation-stats--c1e2c";
export var nationStatus = "nationTemplate-module--nation-status--291c9";
export var nations = "nationTemplate-module--nations--a5a77";